import { yupResolver } from '@hookform/resolvers/yup';
import { ERROR_MESSAGE } from 'constants/form-constants';
import * as yup from 'yup';

export const FORM_FIELDS = {
    FULL_NAME: 'name',
    EMAIL: 'email',
    DESCRIPTION: 'content',
    PRIVACY_POLICY: 'privacyPolicy',
    NDA: 'sendNda',
};

export const validationSchema = yupResolver(
    yup.object().shape({
        [FORM_FIELDS.FULL_NAME]: yup
            .string()
            .required(ERROR_MESSAGE.REQUIRED_FIELD)
            .min(2, ERROR_MESSAGE.MIN_CHARS(2))
            .max(100, ERROR_MESSAGE.MAX_CHARS(100)),
        [FORM_FIELDS.EMAIL]: yup
            .string()
            .required(ERROR_MESSAGE.REQUIRED_FIELD)
            .email(ERROR_MESSAGE.EMAIL)
            .max(200, ERROR_MESSAGE.MAX_CHARS(200)),
        [FORM_FIELDS.DESCRIPTION]: yup
            .string()
            .max(4000, ERROR_MESSAGE.MAX_CHARS(4000)),
        [FORM_FIELDS.PRIVACY_POLICY]: yup
            .bool()
            .oneOf([true], ERROR_MESSAGE.PRIVACY_POLICY),
    }),
);
