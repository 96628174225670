import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { FORM_FIELDS } from 'views/contact-us/components/form/validators';
import { Button } from 'components/button-new';
import { Checkbox } from 'components/checkbox-new';
import { Input } from 'components/input-new';
import { PrefixLangLink } from 'components/prefix-lang-link';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';

const SFormWrapper = styled.div`
    margin: 2rem 0 2.25rem;
    display: flex;
    flex-direction: column;
    gap: 1.75rem;

    ${CONSTANTS.MEDIA.maxHeight960`
    margin: 2rem 0 1.5rem;  
    gap: 1.5rem;
    `}
`;

const SButton = styled(Button)`
    width: fit-content;
    margin-top: 1rem;
`;

const SCheckboxes = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const SPrefixLangLink = styled(PrefixLangLink)`
    color: inherit;
    text-decoration: underline;
`;

export const ContactUsForm = ({ onSubmit, control, errors }) => {
    const { formatMessage } = useIntl();

    return (
        <form id="contact-us-form" onSubmit={onSubmit}>
            <SFormWrapper>
                <Input
                    name={FORM_FIELDS.FULL_NAME}
                    control={control}
                    label={formatMessage({
                        id: 'contact-us-page.fullName',
                    })}
                    error={errors[FORM_FIELDS.FULL_NAME]}
                    isRequired
                />
                <Input
                    name={FORM_FIELDS.EMAIL}
                    control={control}
                    label={formatMessage({
                        id: 'contact-us-page.businessEmail',
                    })}
                    type="email"
                    error={errors[FORM_FIELDS.EMAIL]}
                    isRequired
                />
                <Input
                    name={FORM_FIELDS.DESCRIPTION}
                    control={control}
                    label={formatMessage({
                        id: 'contact-us-page.describeYourProject',
                    })}
                    error={errors[FORM_FIELDS.DESCRIPTION]}
                    as="textarea"
                />
                <SCheckboxes>
                    <Checkbox
                        name={FORM_FIELDS.PRIVACY_POLICY}
                        control={control}
                        label={formatMessage(
                            {
                                id: 'general.privacyPolicyTerms',
                            },
                            {
                                link: (chunks) => (
                                    <SPrefixLangLink to={PATHS.PRIVACY_POLICY}>
                                        {chunks}
                                    </SPrefixLangLink>
                                ),
                            },
                        )}
                        error={errors[FORM_FIELDS.PRIVACY_POLICY]}
                        isRequired
                    />
                    <Checkbox
                        name={FORM_FIELDS.NDA}
                        control={control}
                        label={formatMessage({
                            id: 'contact-us-page.iNeedToSign',
                        })}
                    />
                </SCheckboxes>
                <SButton
                    component="submit"
                    submitValue={formatMessage({
                        id: 'contact-us-page.sendTheMessage',
                    })}
                    testId="send_message"
                />
            </SFormWrapper>
        </form>
    );
};
