import React, { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ContactUsForm } from 'views/contact-us/components/form';
import {
    FORM_FIELDS,
    validationSchema,
} from 'views/contact-us/components/form/validators';
import { CONTACT_DATA } from 'views/contact-us/constants';
import { sendContactEmail } from 'api/contact';
import { Container } from 'components/container';
import { Dialog } from 'components/dialog';
import { resolveDialogContent } from 'components/dialog/utilities';
import { DialogContent } from 'components/dialog-content';
import { HeaderFirst } from 'components/header-first';
import { Logo } from 'components/navbar/components/logo';
import { Overlay } from 'components/overlay';
import { Paragraph } from 'components/paragraph';
import { SocialMedia } from 'components/social-media';
import { FORM_STATUSES } from 'constants/form-statuses';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { GTAG_CONSTANTS } from 'constants/gtag-constants';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useReCaptcha } from 'hooks/use-re-captcha';
import { ClutchReviews } from 'modules/page-footer/components/clutch-section/clutch-reviews';
import { FooterList } from 'modules/page-footer/components/footer-list';
import LocationIcon from 'svgs/icons/ic-location.svg';
import QuoteDownIcon from 'svgs/icons/ic-quote-down.svg';
import {
    getPageUrl,
    gtagReportConversion,
    trackCustomEventGoogleAnalytics,
    trackLinkedInCampaign,
} from 'utilities/index';

import { customDialogContent } from './constants';

const SHeaderFirstLeftAligned = styled(HeaderFirst)`
    font-size: clamp(1.75rem, 1.5739rem + 0.7512vw, 2.25rem);
    text-align: left;

    * {
        text-align: left;
    }
`;

const SDescription = styled(Paragraph)`
    margin-bottom: 1.75rem;
    margin-top: 0.5rem;
    font-size: 1rem;
    line-height: 1.35em;
`;

const SLink = styled.a`
    color: var(--primary-color);
`;

const SContainer = styled(Container)`
    display: flex;
    ${CONSTANTS.MEDIA.max768`
        padding: 0;
        flex-direction: column;
    `}
`;

const SLeft = styled.div`
    flex: 0.6;
    padding: 0 3rem 0 0;
    ${CONSTANTS.MEDIA.max768`
        padding: 0 2rem 3rem;
    `}
    ${CONSTANTS.MEDIA.max500`
        padding: 0 1rem 3rem;
    `};
`;

const SRight = styled.div`
    flex: 0.45;
    display: flex;
    flex-direction: column;
    padding: 7rem 3rem 2rem 3rem;
    min-height: 100vh;
    background-color: var(--gray-color-90);
    ${CONSTANTS.MEDIA.max768`
        padding: 3rem 2rem;
        min-height: auto;
    `}
    ${CONSTANTS.MEDIA.max500`
        padding: 3rem 1rem;
    `};
`;

const SFollowUs = styled(Paragraph)`
    font-size: 0.875rem;
    font-weight: bold;
`;

const SSocialMediaWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const SCeo = styled.div`
    display: flex;
    align-items: center;
    gap: 1.125rem;
    margin-bottom: 1.5rem;
`;

const SName = styled(Paragraph)`
    font-weight: 600;
`;

const SPosition = styled(Paragraph)`
    font-size: 0.875rem;
    font-weight: 400;
`;

const SReCaptcha = styled(Paragraph)`
    font-size: 0.875rem;
    margin: 1rem 0;
    color: var(--gray-color-80);
`;

const SQuote = styled(Paragraph)`
    font-size: 1.125rem;
    font-style: italic;
    line-height: 1.3;
    margin-bottom: 3.25rem;

    ${CONSTANTS.MEDIA.maxHeight960`
        margin-bottom: 2.5rem;
        font-size: 1.06rem;
    `}
`;

const SClutchWidget = styled(ClutchReviews)`
    margin-bottom: 2.5rem;
    max-width: unset;
`;

const SContact = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-left: 2.5rem;
    position: relative;

    ${CONSTANTS.MEDIA.maxHeight960`
        gap: 1.5rem;
    `}
`;

const SLocationIcon = styled(LocationIcon)`
    position: absolute;
    left: 0;
    top: 0;
`;

const SLogoWrapper = styled.div`
    height: 6rem;
    display: flex;
    align-items: center;
    margin-bottom: clamp(1rem, 2.5vh, 2.75rem);
    ${CONSTANTS.MEDIA.max1024`
        height: 5rem;
        margin-bottom: 2.25rem;
    `}
`;

const SFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;

    ${CONSTANTS.MEDIA.max1280`
        flex-direction: column;
        align-items: flex-start;
    `}
`;

export const ContactUsView = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [formStatus, setFormStatus] = useState(FORM_STATUSES.IDLE);

    const images = useStaticQuery(graphql`
        {
            ceo: file(relativePath: { eq: "team/ceo-avatar-new.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        width: 74
                        placeholder: NONE
                        formats: [PNG]
                    )
                }
            }
        }
    `);
    const dialogRef = useRef();
    const {
        control,
        formState: { isDirty, errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: validationSchema,
        defaultValues: {
            [FORM_FIELDS.FULL_NAME]: '',
            [FORM_FIELDS.EMAIL]: '',
            [FORM_FIELDS.DESCRIPTION]: '',
            [FORM_FIELDS.PRIVACY_POLICY]: false,
            [FORM_FIELDS.NDA]: false,
        },
    });

    const onFormSubmit = useCallback((status) => {
        setFormStatus(status);

        if (status !== FORM_STATUSES.IDLE) {
            dialogRef?.current?.showModal();
        }
    }, []);

    const onFailedLoad = () =>
        onFormSubmit(FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR);

    const { requestRecaptchaValidation } = useReCaptcha({
        isDirty,
        onFailedLoad,
    });

    const onSubmit = handleSubmit(async ({ name, email, content, sendNda }) => {
        try {
            setIsLoading(true);
            const token = await requestRecaptchaValidation();
            await sendContactEmail({
                name,
                email,
                content,
                recaptchaResponse: token,
                sendNda,
            });

            trackLinkedInCampaign();
            trackCustomEventGoogleAnalytics({
                ...gaTrackedEvents.SALES.CONTACT_FROM,
                label: getPageUrl(),
            });
            gtagReportConversion(GTAG_CONSTANTS.CONTACT_FORM);
            onFormSubmit(FORM_STATUSES.SUCCESS);
        } catch {
            onFormSubmit(FORM_STATUSES.ERROR);
        } finally {
            reset();
            setIsLoading(false);
        }
    });

    const onClose = () => {
        if (formStatus === FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR) {
            window.location.reload();
            return;
        }

        dialogRef?.current?.close();
        setFormStatus(FORM_STATUSES.IDLE);
    };

    return (
        <>
            <SContainer margin={false}>
                <SLeft>
                    <SLogoWrapper>
                        <Logo />
                    </SLogoWrapper>
                    <SHeaderFirstLeftAligned topParagraph="contact-us-page.contact">
                        <FormattedMessage id="contact-us-page.tellUsAbout" />
                    </SHeaderFirstLeftAligned>
                    <SDescription>
                        <FormattedMessage
                            id="contact-us-page.description"
                            values={{
                                link: (chunks) => (
                                    <SLink href={PATHS.ESTIMATE_THE_PROJECT}>
                                        {chunks}
                                    </SLink>
                                ),
                            }}
                        />
                    </SDescription>
                    <ContactUsForm
                        control={control}
                        errors={errors}
                        onSubmit={onSubmit}
                    />
                    <SFooter>
                        <SSocialMediaWrapper>
                            <SFollowUs>
                                <FormattedMessage id="contact-us-page.followUs" />
                            </SFollowUs>
                            <SocialMedia isFooter />
                        </SSocialMediaWrapper>
                        <SReCaptcha>
                            <FormattedMessage id="general.recaptcha" />
                        </SReCaptcha>
                    </SFooter>
                </SLeft>
                <SRight>
                    <SCeo>
                        <GatsbyImage
                            image={images.ceo.childImageSharp.gatsbyImageData}
                            alt="Matt Sadowski"
                        />
                        <div>
                            <SName>
                                <FormattedMessage id="contact-us-page.ceo" />
                            </SName>
                            <SPosition>
                                <FormattedMessage id="contact-us-page.ceoOfMobileReality" />
                            </SPosition>
                        </div>
                    </SCeo>
                    <QuoteDownIcon />
                    <SQuote>
                        <FormattedMessage id="contact-us-page.inOurOrganization" />
                    </SQuote>
                    <SClutchWidget widgetType="13" />
                    <SContact>
                        <SLocationIcon />
                        {CONTACT_DATA.map((item) => (
                            <FooterList key={item.title} item={item} />
                        ))}
                    </SContact>
                </SRight>
            </SContainer>
            <Dialog ref={dialogRef} testId="form_popup">
                <DialogContent
                    {...resolveDialogContent({
                        formStatus,
                        customDialogContent,
                    })}
                    onClose={onClose}
                />
            </Dialog>
            <Overlay show={isLoading} />
        </>
    );
};
